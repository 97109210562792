import { css, keyframes } from '@emotion/react'
import React from 'react'

import { mq } from '../theme/mixins'

const PageIntroTextbox = ({ heading, bodyNode, ...props }) => {
  const animateIn = keyframes`
  from {
    transform: scale3d(1.2, 1.2, 1);
    opacity: 0;
  }
  to {
    transform: scale3d(1, 1, 1);
    opacity: 1;
  }
  `
  const textboxStyle = css`
    margin-top: calc(var(--gutter-lg) + 2rem);
    margin-bottom: 5rem;
    grid-column: 2 / span 7;
    z-index: 2;
    background-color: white;
    padding: 1rem var(--gutter-mlg) 3rem;
    max-width: max-content;
    animation: ${animateIn} 1000ms cubic-bezier(0.25, 0.75, 0.25, 1)
      forwards;
    h1 {
      font-size: var(--fs-48);
      font-weight: 300;
      margin-top: 0.5em;
      margin-bottom: 0.333em;
    }
    .body {
      font-size: var(--fs-18);
      > :first-of-type {
        margin-top: 0;
      }
      > :last-of-type {
        margin-bottom: 0;
      }
    }
    ${mq().ml} {
      grid-column-end: span 9;
    }
    ${mq().ms} {
      grid-column-end: span 12;
    }
  `
  return (
    <div css={textboxStyle} {...props}>
      <h1>{heading}</h1>
      <div
        className="body"
        dangerouslySetInnerHTML={{
          __html: bodyNode.childMarkdownRemark.html,
        }}
      />
    </div>
  )
}

export default PageIntroTextbox
