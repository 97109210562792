import { css } from '@emotion/react'
import { graphql, useStaticQuery } from 'gatsby'
import { tint } from 'polished'
import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import Layout from '../components/Layout'
import PageIntroTextbox from '../components/PageIntroTextbox'
import ProductFilters from '../components/ProductFilters'
import ProductGrid from '../components/ProductGrid'
import Seo from '../components/Seo'
import { useWindowWidth } from '../hooks/useWindowDimensions'
import { baseGrid, mq } from '../theme/mixins'
import { breakpoints, colors } from '../theme/variables'
import { filtersToParams } from '../utils/helpers'

const ProductsOverviewPage = () => {
  const { productsPage } = useStaticQuery(graphql`
    query {
      productsPage: datoCmsProductsOverview {
        slug
        seo {
          title
          description
        }
        heading
        bodyNode {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  `)

  const [filters, setFilters] = useState({
    woodType: null,
    woodFinish: null,
    doorStyles: [],
  })

  const queryString = useMemo(() => filtersToParams(filters), [filters])

  const windowWidth = useWindowWidth()
  const contentWrapRef = useRef(null)
  const filtersWrapRef = useRef(null)
  const filtersRef = useRef(null)

  const [stuck, setStuck] = useState(null)

  let requestRunning = useRef(false)
  const handleSetSticky = () => {
    if (!requestRunning.current) {
      window.requestAnimationFrame(() => {
        const contentPosY =
          contentWrapRef.current?.getBoundingClientRect().y
        const filtersHeight =
          filtersRef.current?.getBoundingClientRect().height
        const posBottom =
          contentWrapRef.current?.getBoundingClientRect().bottom -
          filtersHeight
        const internalScrollDist =
          filtersWrapRef.current?.getBoundingClientRect().y -
          filtersRef.current?.getBoundingClientRect().y
        if (contentPosY <= 0 && posBottom + internalScrollDist > 0) {
          setStuck(true)
        } else {
          setStuck(false)
        }
        requestRunning.current = false
      })
      requestRunning.current = true
    }
  }

  useLayoutEffect(() => {
    const observer =
      typeof window !== 'undefined' &&
      new IntersectionObserver(entries => {
        const [{ isIntersecting }] = entries
        if (isIntersecting) {
          window.addEventListener('scroll', handleSetSticky, {
            capture: false,
            passive: true,
          })
        } else {
          window.removeEventListener('scroll', handleSetSticky, {
            capture: false,
            passive: true,
          })
        }
      })
    if (windowWidth > breakpoints.m) {
      observer.observe(contentWrapRef.current)
    }
    return () => {
      observer.disconnect()
      window.removeEventListener('scroll', handleSetSticky, {
        capture: false,
        passive: true,
      })
    }
  }, [contentWrapRef, windowWidth])

  useEffect(() => {
    filtersWrapRef.current?.scrollTo(0, 0)
  }, [stuck])

  const layoutStyle = css`
    background-color: ${tint(0.25, colors.ecruShade1)};
    ${mq().ms} {
      background-color: ${colors.ecru};
    }
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: calc(100% - var(--gutter-sm));
      height: 100%;
      grid-column: 1 / span 5;
      background-color: ${colors.ecru};
      ${mq().ms} {
        display: none;
      }
    }
  `
  const mainStyle = css`
    ${baseGrid}
  `
  const introStyle = css`
    margin-bottom: 4rem;
    ${mq().ms} {
      margin-bottom: 2.5rem;
    }
  `
  const contentWrapStyle = css`
    ${baseGrid}
    grid-column: 1 / -1;
  `
  const filtersWrapStyle = css`
    grid-column: 1 / span 5;
    margin-right: var(--gutter-sm);
    background-color: ${colors.ecru};
    top: 0;
    align-self: flex-start;
    box-sizing: border-box;
    position: sticky;
    ${stuck &&
    css`
      max-height: calc(100 * var(--vh, 1vh));
      overflow: auto;
    `}
    ${mq().ms} {
      position: relative;
      overflow: unset;
      max-height: none;
      grid-column: 2 / -2;
      margin: 0;
    }
  `
  const filtersSectionStyle = css`
    padding: 1rem var(--gutter-mlg) var(--gutter-sm) var(--margin-outer);
    box-sizing: border-box;
    ${mq().ms} {
      padding: 0;
    }
  `
  const filterStyle = css`
    ul {
      ${mq().m} {
        --item-size: 25%;
      }
      ${mq().ms} {
        --item-size: 4em;
      }
      ${mq().s} {
        --item-size: 3.5em;
      }
    }
  `
  const productGrid = css`
    grid-column: 6 / span 8;
    align-self: flex-start;
    margin-top: 1rem;
    ${mq().ms} {
      grid-column: 2 / -2;
    }
  `
  return (
    <Layout css={layoutStyle} mainCss={mainStyle}>
      <Seo
        title={productsPage.seo.title}
        description={productsPage.seo.description}
      />
      <PageIntroTextbox
        heading={productsPage.heading}
        bodyNode={productsPage.bodyNode}
        css={introStyle}
      />
      <div css={contentWrapStyle} ref={contentWrapRef}>
        <div css={filtersWrapStyle} ref={filtersWrapRef}>
          <ProductFilters
            ref={filtersRef}
            css={filtersSectionStyle}
            filterCss={filterStyle}
            onFilterChange={newFilters => setFilters(newFilters)}
            collapsible
          />
        </div>
        <ProductGrid
          css={productGrid}
          filters={filters}
          urlPrefix={productsPage.slug}
          urlQuery={'?' + queryString}
        />
      </div>
    </Layout>
  )
}

export default ProductsOverviewPage
