import { css } from '@emotion/react'
import { Link } from 'gatsby'
import React from 'react'

import { mq } from '../theme/mixins'
import { colors } from '../theme/variables'
import { toSlug } from '../utils/helpers'
import ProductImage from './ProductImage'

const ProductThumbnail = ({
  product,
  index,
  woodType,
  woodFinish,
  urlPrefix = '',
  urlQuery = '',
  ...props
}) => {
  const thumbnailStyle = css`
    text-decoration: none;
    color: ${colors.grayDark};
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 0.75rem auto;
    animation-delay: calc(
      ${Math.floor(index / 4) * 100}ms + ${75 * (index % 4)}ms + 50ms
    );
    ${mq().ml} {
      animation-delay: calc(
        ${Math.floor(index / 3) * 100}ms + ${75 * (index % 3)}ms + 50ms
      );
    }
    ${mq().s} {
      animation-delay: calc(
        ${Math.floor(index / 2) * 100}ms + ${75 * (index % 2)}ms + 50ms
      );
    }
    h2 {
      position: relative;
      display: block;
      grid-row: 2 / 4;
      grid-column: 1 / -1;
      background-color: white;
      margin: 0;
      justify-self: center;
      width: fit-content;
      font-size: var(--fs-18);
      font-weight: 400;
      padding: 0.375em 0.75em;
      color: ${colors.grayDark};
      transition: color 300ms ease, background-color 300ms ease;
      white-space: nowrap;
      overflow-x: hidden;
      text-overflow: ellipsis;
      box-sizing: border-box;
      max-width: calc(100% + 0.5rem);
    }
    @media (hover: hover) {
      &:hover h2 {
        color: white;
        background-color: ${colors.green};
      }
    }
  `
  const thumbnailImage = css`
    grid-row: 1 / 3;
    grid-column: 1 / -1;
    position: relative;
    transform-origin: 50% 100%;
    transition: transform 500ms ease-out;
    transform: none;
    @media (hover: hover) {
      a:hover > & {
        transform: scale3d(1.025, 1.025, 1);
        transition-duration: 150ms;
      }
    }
  `
  return (
    <Link
      to={`/${urlPrefix}/${toSlug(product.name)}/${urlQuery}`}
      css={thumbnailStyle}
      {...props}
      loading="lazy"
    >
      <ProductImage
        css={thumbnailImage}
        product={product}
        woodType={woodType}
        woodFinish={woodFinish}
      />
      <h2>{product.name}</h2>
    </Link>
  )
}
export default ProductThumbnail
