import { css, keyframes } from '@emotion/react'
import { graphql, useStaticQuery } from 'gatsby'
import React, { useEffect, useState } from 'react'

import { mq } from '../theme/mixins'
import ProductThumbnail from './ProductThumbnail'

const ProductGrid = ({
  filters,
  urlPrefix = '',
  urlQuery = '',
  ...props
}) => {
  const { products } = useStaticQuery(graphql`
    query {
      products: allDatoCmsProduct(
        sort: { fields: position, order: ASC }
      ) {
        nodes {
          name
          doorStyle {
            name
          }
          fallbackProductImage {
            gatsbyImageData(imgixParams: { q: 50 }, width: 240)
            alt
          }
          productImages {
            woodType {
              name
            }
            stainedImages {
              finish: stain {
                name
              }
              image {
                gatsbyImageData(imgixParams: { q: 50 }, width: 240)
                alt
              }
            }
            paintedImages {
              finish: paint {
                name
              }
              image {
                gatsbyImageData(imgixParams: { q: 50 }, width: 240)
                alt
              }
            }
          }
        }
      }
    }
  `)

  const [ready, setReady] = useState(true)
  useEffect(() => {
    setReady(false)
    const delay = setTimeout(() => {
      setReady(true)
    }, 10)
    return () => clearTimeout(delay)
  }, [filters.doorStyle])

  const animation = keyframes`
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  `
  const gridStyle = css`
    --number-columns: 4;
    display: grid;
    grid-column-gap: var(--gutter-sm);
    grid-row-gap: calc(var(--gutter-sm) + 0.5rem);
    grid-template-columns: repeat(var(--number-columns), 1fr);
    margin-bottom: var(--gutter-xlg);
    ${mq().ml} {
      --number-columns: 3;
      grid-column-gap: calc(var(--gutter-sm) + 0.5rem);
    }
    ${mq().s} {
      --number-columns: 2;
      grid-column-gap: var(--gutter-md);
    }
  `
  const thumbnailStyle = css`
    opacity: 0;
    animation-name: ${animation};
    animation-duration: 1200ms;
    animation-timing-function: cubic-bezier(0.25, 0.75, 0.25, 1);
    animation-fill-mode: forwards;
    ${!ready &&
    css`
      display: none;
    `}
  `

  let index = -1
  return (
    <section css={gridStyle} {...props}>
      {filters.woodType &&
        products.nodes.map((product, i) => {
          if (
            !filters.doorStyle ||
            filters.doorStyle === product.doorStyle.name
          ) {
            index += 1
            return (
              <ProductThumbnail
                css={thumbnailStyle}
                key={product.name}
                index={index}
                woodType={filters.woodType}
                woodFinish={filters.woodFinish}
                product={product}
                urlPrefix={urlPrefix}
                urlQuery={urlQuery}
              />
            )
          } else return <React.Fragment key={i} />
        })}
    </section>
  )
}

export default ProductGrid
